/************ Navbar Css ************/
nav.navbar {
  width: 100%;
  top: 0;
  z-index: 9;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  position: fixed;
  padding: 0px 0;
  background-image: url("../assets/images/nav-bg5.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgb(255 255 255) !important;
}

nav .navbar-brand {
  width: 15%;
  height: auto;
}

nav .d-flex {
  width: 40%;
  padding: 8px 0px;
}

nav .input-group .btn {
  border: none;
  padding: 0;
  width: 70px;
  height: 100%;
}

nav .input-group .btn:hover {
  background-color: transparent !important;
  border-radius: 26px;
}

nav .input-group input {
  line-height: 2;
  border-radius: 12px;
}

nav .input-group .input-group-text {
  background-color: #31891f;
  color: black;
  border-radius: 12px;
  padding: 0;
}

.navSideActions a:hover {
  color: #f37b4c;
}

.nav_contact {
  display: flex !important;
}

.custom_navbar_dropdown .dropdown-menu {
  width: 218px;
  text-align: left;
}

.custom_navbar_dropdown .btn {
  background-color: #3dff1f;
  color: black;
  padding: 10px 35px;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  width: 100%;
}

.custom_navbar_dropdown .btn:hover {
  background-color: black;
  color: white;
}

.custom_navbar_dropdown a {
  color: #686868;
}

.custom_navbar_dropdown a:hover {
  color: #f37b4c;
}

@media (min-width: 576px) {
  .navSideActions {
    display: flex;
  }

  .navSideActions a {
    margin: 0 15px;
  }
}

/* end */

.phone_actions {
  display: none;
}

@media (max-width: 481px) {
  .phone_actions {
    display: block;
    position: sticky;
    top: 5%;
    z-index: 99;
    background-color: white;
  }

  .hovering_login_register {
    width: 100%;
    display: flex;
  }

  .hovering_login_register .col-sm-6 {
    width: 50%;
  }

  .hovering_login_register .btn {
    border-radius: 0;
    border: 1px solid white;
    width: 100%;
    font-weight: 500;
  }

  .hovering_login_register .dropdown-toggle::after {
    display: none;
  }

  .hovering_login_register a {
    color: white;
    display: block;
  }

  .hovering_search_bar {
    margin: 3px;
  }

  .hover_shopping_cart {
    background-color: #4dc900;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    color: #fff;
  }
}

/* Banner css */

.banner {
  height: 90vh;
}

/* .banner .toggle-categories-btn {
  display: none;
} */

.banner .categ_horiz {
  display: none;
}

.banner .col-custom {
  height: 70vh;
}

.banner .col-sm-4 {
  display: flex;
  position: relative;
}

/* Banner Links Box css */

.bannerLinksBox {
  width: 100%;
  /* padding: 10px 25px; */
  text-align: left;
  /* box-shadow: 2px 3px 12px 2px #d9d9d9; */
  background-color: #f5f5f5;
  display: grid;
  position: absolute;
  overflow: auto;
}

.bannerLinksBox ul {
  padding-left: 0 !important;
}

.bannerLinksBox ul li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.bannerLinksBox .dropdown .btn {
  background-color: transparent;
  color: #848282;
  border: none;
}

.moreCategories {
  background-color: #edededf0;
  width: 175%;
  height: 100%;
  position: absolute;
  z-index: 99;
  left: 102%;
  overflow: auto;
  text-align: left;
  border-left: 1px solid #c2c0c0;
}

.linkItem {
  display: block;
  padding: 1px;
  font-size: 18px;
  font-weight: 300;
  color: black;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.linkItem:hover {
  color: rgb(255, 187, 0);
}

.linkItem img {
  height: 22px;
  width: 22px;
  margin-right: 10px;
}

.moreCat_content {
  width: 30%;
  padding: 10px;
  float: left;
  margin: 5px;
  letter-spacing: 0.2px;
  font-size: 15px;
}

.moreCat_content a {
  color: #666666b3;
}

.moreCat_content a:hover {
  color: black !important;
}

.moreCat_content h5 {
  font-size: 1.05em;
}

.moreCat_content hr {
  margin: 0;
}

/* Carousel css */
.corousel {
  /* background-color: #ededed91;
  border-radius: 10px; */
  /* box-shadow: 2px 4px 11px 5px #dddddd; */
  padding: 10px;
}

.corousel .c-overlay {
  position: absolute;
  bottom: 0;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  background-color: #0000007a;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 39px solid #e9e15d; */
}

.c-overlay h5 {
}

.c-overlay a {
  background-color: white;
  padding: 7px 15px;
  margin: 5px;
  border-radius: 20px;
  color: black;
  font-size: 12px;
}

.c-overlay a:hover {
  background-color: transparent;
  border: 1px solid white;
  color: white;
}

/* .corousel .c-overlay .c-featured {
  position: absolute;
  left: 0;
  background-color: #f1a689;
  width: 25%;
  padding: 13px;
  font-weight: 300;
  font-size: large;
  text-align: start;
  clip-path: polygon(0% 0%, 100% 0, 80% 100%, 0% 100%);
}

.corousel .c-overlay .c-shopnow {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #f1a689;
  color: #18443c;
  width: 20%;
  padding: 10px;
  font-size: large;
  text-align: center;
  clip-path: polygon(18% 0%, 100% 0, 100% 100%, 0% 100%);
}

.corousel .c-overlay .c-shopnow:hover {
  color: lightblue;
} */

.corousel .c-item img {
  height: 81vh;
  width: auto;
}
/* .react-multi-carousel-item {
  box-shadow: 19px -20px 26px 23px #0000001a inset;
} */

.banner_image_placeholder {
  height: 81vh;
  width: auto;
  background-color: #80808030;
}

@media screen and (max-width: 1084px) {
}

@media screen and (max-width: 992px) {
  .navbar-collapse {
    background-color: #ededed;
    padding: 20px;
    text-align: left;
    position: absolute;
    transform: translate3d(-50px, 92px, 0px);
    z-index: 99;
  }

  .navbar-collapse > * {
    justify-content: space-evenly;
  }

  nav .spacer > * {
    margin-left: 0;
    padding: 10px;
  }

  .navbar-collapse form {
    display: none !important;
  }

  #collapsed-search {
    display: block;
  }

  #collapsed-search .d-flex {
    width: 70%;
  }

  .banner {
    padding-top: 15px;
  }

  /* .banner .toggle-categories-btn {
    display: block;
  } */

  .banner .categ_horiz {
    display: flex;
    overflow: auto;
    font-size: 22px;
  }

  .categ_horiz ul {
    list-style: none;
    overflow-x: auto;
    white-space: nowrap;
    flex: none;
    padding: 0;
  }

  .categ_horiz li {
    float: left;
    margin-right: 15px;
  }

  .categ_horiz a {
    color: black;
  }

  .bannerLinksBox {
    position: absolute;
    /* display: block; */
    transform: translate3d(0px, 10px, 0px);
    z-index: 99;
    background-color: #ededed;
  }

  .show-bannerLinksBox {
    display: block;
  }

  .hide-bannerLinksBox {
    display: none;
  }

  /* .banner .col-sm-8 {
    width: 94%;
  } */
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 481px) {
  nav.navbar {
    position: sticky;
    padding: 10px 0;
    background-image: url("../assets/images/nav-bg5.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  nav.navbar a.navbar-brand {
    width: 25%;
    padding: 0;
    margin: 0;
  }

  #collapsed-search .d-flex {
    width: 100%;
  }

  .banner {
    height: auto;
  }

  .corousel .c-item img {
    /* height: 35vh; */
    width: 100%;
    height: auto;
  }

  .corousel .c-item {
    height: 100%;
    position: relative;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* .corousel .c-overlay .c-shopnow {
    width: 30%;
    font-size: small;
    padding: 5px;
  }

  .corousel .c-overlay .c-featured {
    width: 25%;
    padding: 5px;
    font-size: small;
  } */
}
