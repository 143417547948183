.footer {
  margin-top: 30px;
  padding: 20px 0;
  box-shadow: -1px -28px 15px #4140400a;
  text-align: left;
}

.footer img {
  width: 50%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #6b6969;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.footer .map {
  margin-bottom: 30px;
  padding: 0;
}

.footer .contact-details {
  text-align: left;
}

.footer .other-links {
  display: inline-grid;
  text-align: left;
}

.footer .top_part {
  border-bottom: 1px solid #cfcfcf;
  padding: 20px;
}

.footer .top_part p {
  color: #8f8f8f;
}

.footer .top_part .col-sm-4 p {
  margin-bottom: 0;
}

.footer .top_part ul {
  padding: 0 !important;
  list-style: none;
}

.footer .top_part ul li {
  margin: 10px 0px;
}

.footer .top_part a {
  color: #6f6f6f;
  font-weight: 300;
  word-break: break-all;
}

.footer .top_part a:hover {
  color: #1f5fff;
}

.footer .bottom_part {
  padding: 15px;
  text-align: center;
}

.footer .bottom_part p {
  margin: 0;
}

.footer .bottom_part a {
  color: #686868;
}

.footer .bottom_part a:hover {
  color: #3d1fff;
}

@media (max-width: 481px) {
  .footer .top_part .col-sm-3 {
    width: 49%;
  }

  .footer .bottom_part p {
    font-size: 13px;
  }

  .footer .top_part {
    padding: 10px;
  }
}
